@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    @variants dark {
        .half-border::after{
            content: " ";
            background: rgb(260, 260, 260);
            width: 121px;
            position: relative;
            height: 8px;
            left: -8px;
            top: -261px;
            display: block;
            z-index: -1;
        }

        .half-border-dark::after{
            background: rgb(17, 24, 39);
        }
        .logo-bg-dark{
            background: rgb(70,81,96);
            background: radial-gradient(circle, rgba(70,81,96,1) 0%, rgba(31,40,54,1) 34%, rgba(19,24,31,1) 100%);
        }
        .logo-bg{
            background: rgb(187,187,187);
            background: radial-gradient(circle, rgba(187,187,187,1) 0%, rgba(241,241,241,1) 34%, rgba(255,255,255,1) 100%);
        }
    }
}
