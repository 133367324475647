.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.half-a-border-on-top:after {
  content: " ";
  width:200px;
  height:90px;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
  /* position: absolute; */
  top:0;
  left:0;
  z-index: 99;
  @apply bg-gray-900 dark:bg-white;
}

div{
  @apply dark:text-white;
}

/* div{
  @apply border-2 border-white dark:text-white;
}
section{
  @apply bg-gray-700;
} */